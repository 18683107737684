import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "../slider.css";
import { AiOutlineCheckCircle, AiOutlineArrowRight } from "react-icons/ai";

import carData from "../data/PBHbusjes.json";

function CarList() {
  const location = useLocation();
  const history = useNavigate();
  const [cars, setCars] = useState(carData);
  const [filteredCars, setFilteredCars] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage] = useState(15);
  const queryParams = new URLSearchParams(location.search);
  const selectedPage = parseInt(queryParams.get("page")) || 1;
  const selectedLeaseType = queryParams.get("leaseType") || "Alle";
  const selectedPartner = queryParams.get("partner") || "All";
  const selectedSortOrder = queryParams.get("sortOrder") || "laag-hoog";
  const [isLoading, setIsLoading] = useState(false);
  const [popupCar, setPopupCar] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const brandFilters = queryParams.getAll("brand");

  const handlePriceChange = (newPriceRange) => {
    setPriceRange(newPriceRange);
  };

  useEffect(() => {
    if (selectedPage !== page) {
      setPage(selectedPage);
    }
  }, [selectedPage, page]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    let filtered = cars;

    if (selectedLeaseType !== "Alle") {
      filtered = filtered.filter(
        (car) =>
          car.specs.engine.toLowerCase() === selectedLeaseType.toLowerCase()
      );
    }

    if (selectedPartner !== "All") {
      filtered = filtered.filter(
        (car) => car.Partner.toLowerCase() === selectedPartner.toLowerCase()
      );
    }

    if (selectedSortOrder === "laag-hoog") {
      filtered = filtered.sort(
        (a, b) => parseFloat(a.price) - parseFloat(b.price)
      );
    } else {
      filtered = filtered.sort(
        (a, b) => parseFloat(b.price) - parseFloat(a.price)
      );
    }
    filtered = filtered.filter(
      (car) =>
        parseFloat(car.price) >= priceRange[0] &&
        parseFloat(car.price) <= priceRange[1]
    );

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const slicedCars = filtered.slice(startIndex, endIndex);
    setFilteredCars(slicedCars);
  }, [
    cars,
    page,
    perPage,
    selectedLeaseType,
    selectedPartner,
    selectedSortOrder,
    isLoading,
    priceRange,
  ]);

  const partnerLogos = {
    Oscar: require("../assets/oscar.png"),
    Snappcar: require("../assets/snappcar.png"),
  };

  useEffect(() => {
    const queryParams = new URLSearchParams();
    queryParams.set("page", page.toString());
    queryParams.set("leaseType", selectedLeaseType);
    queryParams.set("partner", selectedPartner);
    queryParams.set("sortOrder", selectedSortOrder);

    history(`?${queryParams.toString()}`);
  }, [page, selectedLeaseType, selectedPartner, selectedSortOrder, history]);

  const handlePreviousPage = () => {
    if (page > 1) {
      const prevPage = page - 1;
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("page", prevPage.toString());
      history(`?${queryParams.toString()}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleNextPage = () => {
    const nextPage = page + 1;
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", nextPage.toString());
    history(`?${queryParams.toString()}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleOpenPopup = (car) => {
    setPopupCar(car);
  };

  const handlePartnerChange = (selectedValue) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("partner", selectedValue);
    queryParams.delete("page");
    history({ search: queryParams.toString() });
  };

  const handleSortChange = () => {
    const newSortOrder =
      selectedSortOrder === "laag-hoog" ? "hoog-laag" : "laag-hoog";
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("sortOrder", newSortOrder);
    queryParams.delete("page");
    history({ search: queryParams.toString() });
  };

  return (
    <div className="container mx-auto p-4 flex flex-col sm:flex-row min-h-screen">
      <div className="w-full sm:w-1/4 p-4 sm:mt-[14vh]">
        <h1 className="sm:hidden text-3xl sm:text-5xl font-bold mb-8 mt-4 sm:mt-[8vh] text-secondary-color">
          Vind jouw ideale{" "}
          <span className="text-primary-color">Persoons-Busje</span> vandaag!
        </h1>
        <h1 className="text-xl font-bold mb-2 text-secondary-color">
          Filter Opties:
        </h1>
        <button
          onClick={handleSortChange}
          className="text-primary-color mb-2 font-bold bg-white border-2 border-primary-color py-2 px-4 rounded-xl hover:bg-[#dacbff] transition-colors duration-300"
        >
          Sorteer:{" "}
          {selectedSortOrder === "laag-hoog" ? "Laag-Hoog" : "Hoog-Laag"}
        </button>
        <select
          value={selectedPartner || "All"}
          onChange={(e) => handlePartnerChange(e.target.value)}
          className="border p-2 w-full rounded-md mb-2 text-secondary-color font-bold"
        >
          <option value="All">Alle Partners</option>
          <option value="oscar">Oscar</option>
          <option value="snappcar">Snappcar</option>
        </select>

        <div className="mt-2 mb-3 bg-white border-2 py-2 px-4 rounded-xl">
          <h3 className="font-bold mb-2 text-secondary-color">Prijsbereik</h3>
          <Slider
            range
            min={10}
            max={500}
            defaultValue={priceRange}
            value={priceRange}
            onChange={handlePriceChange}
          />
          <div className="flex justify-between">
            <span>€{priceRange[0]}</span>
            <span>€{priceRange[1]}</span>
          </div>
        </div>
      </div>

      <div className="w-full sm:w-3/4">
        <h1 className="text-transparent text-3xl sm:text-5xl font-bold mb-8 mt-4 sm:mt-[8vh] sm:text-secondary-color">
          Vind jouw ideale{" "}
          <span className="text-transparent sm:text-primary-color">
            Persoons-Busje
          </span>{" "}
          vandaag!
        </h1>
        <ul className="grid grid-cols-1 gap-4 text-[#464646]">
          {filteredCars.length === 0 && (
            <p className="text-4xl font-bold text-primary-color mb-[100vh]">
              Sorry, er zijn geen auto's meer.
            </p>
          )}

          {filteredCars.map((car) => (
            <li
              key={car.id}
              className="border-2 px-4 pb-2 pt-1 rounded-lg bg-white hover:shadow-lg h-auto flex flex-col md:flex-row items-center"
            >
              <div className="md:w-1/3 sm:pr-2 ">
                <a onClick={() => handleOpenPopup(car)}>
                  <img
                    src={car.LinkjesFoto[0]}
                    alt={car.name}
                    className="w-full md:w-[40vh] h-auto mx-auto md:mx-0 cursor-pointer"
                    loading="lazy"
                  />
                </a>
              </div>
              <div className="md:w-2/3 ">
                <p className="text-primary-color">
                  van:{" "}
                  <span className="text-secondary-color font-bold">
                    {car.Partner}
                  </span>
                </p>
                <div className="flex flex-col md:flex-row justify-between items-start">
                  <h2 className="text-3xl font-bold text-left text-secondary-color">
                    {car.name}
                  </h2>
                  <h3 className="text-4xl font-bold text-primary-color text-center sm:text-left">
                    €{car.price.toString().replace(".", ",")}
                    <span className="text-2xl mt-2 sm:mt-0">p/d</span>
                  </h3>
                </div>
                {/* <p>
               
                  <img
                    src={partnerLogos[car.Partner]}
                    alt={car.name}
                    className="w-auto h-8"
                    loading="lazy"
                  />
                </p> */}
                <div className="w-full md:w-[60%] h-auto mt-2">
                  <div className="flex flex-col ml-auto justify-between">
                    <div className="flex items-center text-sm sm:text-md">
                      <AiOutlineCheckCircle
                        className="text-green-600"
                        size={20}
                      />{" "}
                      All-risk
                    </div>
                    <div className="flex items-center text-sm sm:text-md">
                      <AiOutlineCheckCircle
                        className="text-green-600"
                        size={20}
                      />{" "}
                      Wegenbelasting
                    </div>
                    <div className="flex items-center text-sm sm:text-md">
                      <AiOutlineCheckCircle
                        className="text-green-600"
                        size={20}
                      />{" "}
                      Onderhoud
                    </div>
                  </div>
                </div>

                <div className="flex justify-end mt-5 sm:mt-0">
                  <p className="w-full md:w-[30%] h-[6vh] sm:h-[5vh] flex flex-col justify-end">
                    <button
                      onClick={() => window.open(car.AffiliateLink, "_blank")}
                      className="flex items-center text-primary-color justify-center font-bold bg-white border-2 border-primary-color sm:py-2 w-full h-[6vh] px-4 rounded-xl hover:bg-[#dacbff] transition-colors duration-300"
                      rel="nofollow"
                    >
                      <span className="">Bekijk beste prijs</span>
                      <AiOutlineArrowRight />
                    </button>
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="mt-8 mb-10 flex justify-center gap-8">
          <button
            onClick={handlePreviousPage}
            className="border-2 border-primary-color text-primary-color px-4 py-2 rounded-lg w-[6vw] font-bold cursor-pointer hover:bg-[#fff1e7] transition-colors duration-300"
            disabled={page === 1}
          >
            Vorige
          </button>
          <button
            onClick={handleNextPage}
            className="border-2 border-primary-color text-primary-color px-4 py-2 rounded-lg w-[6vw] font-bold cursor-pointer hover.bg-[#fff1e7] transition-colors duration-300"
            style={{
              visibility: filteredCars.length < perPage ? "hidden" : "visible",
            }}
          >
            Volgende
          </button>
        </div>
      </div>
    </div>
  );
}

export default CarList;
