import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CarList from "./components/CarList";
import BlogPage from "./components/BlogPage";
import ScrollToTop from "./components/ScrollToTop";
import NotFoundPage from "./components/NotFoundPage";

const Homepage = lazy(() => import("./components/Landingpage"));
const DemoCards = lazy(() => import("./components/DemoCards"));
const ExtraInfo = lazy(() => import("./components/ExtraInfo"));
const BlogHomepage = lazy(() => import("./components/BlogPreview"));
const CarCards = lazy(() => import("./components/CarBrandCards"));
const AllLinks = lazy(() => import("./components/AllLinks"));

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<EveryElement />} />
          <Route path="/Persoons-Busjes" element={<CarList />} />
          <Route path="/Private-Lease/:blogUrl" element={<BlogPageWrapper />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
}

function EveryElement() {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Homepage />
        <DemoCards />
        <ExtraInfo />
        <BlogHomepage />
        <CarCards />
        <AllLinks />
        <Footer />
      </Suspense>
    </div>
  );
}

function BlogPageWrapper() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BlogPage />
    </Suspense>
  );
}

export default App;
