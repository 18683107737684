import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import blogsData from "../data/blog.json";
import { Link } from "react-router-dom";

const BlogPage = () => {
  const [contentData, setContentData] = useState([]);
  const { blogUrl } = useParams();
  const blog = blogsData.find((blog) => blog.blog_url === blogUrl);

  useEffect(() => {
    if (blog && blog.content) {
      setContentData(blog.content);
    }
  }, [blogUrl]);

  const scrollToHeader = (headerId) => {
    const headerElement = document.getElementById(headerId);
    if (headerElement) {
      headerElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!contentData.length) {
    return (
      <div className="w-full min-h-screen text-5xl text-primary-color font-bold flex justify-center items-center">
        Laden...
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen mx-auto relative">
      <Helmet>
        <title>{contentData[0].text}</title>
        <meta name="description" content={contentData[1].text} />
      </Helmet>
      <div className="relative w-full h-[40vh] overflow-hidden">
        <img
          src={blog.image}
          alt="Blog Banner"
          className="w-full h-full object-cover z-0"
        />
      </div>
      <div className="w-[20vh] absolute ml-[30vh] mt-[20vh]">
        <Link to="/Elektrische-Autos?contract=Private+lease%3FsortOrder%3Dlaag-hoog&leaseType=Private+Lease">
          <button className="text-primary-color font-bold mb-2 bg-white border-2 border-primary-color py-2 px-4 rounded-xl hover:bg-[#fff1e7] transition-colors duration-300">
            Bekijk private lease
          </button>
        </Link>
        <Link to="/Elektrische-Autos?contract=Private+lease%3FsortOrder%3Dlaag-hoog&leaseType=Zakelijk">
          <button className="text-primary-color font-bold bg-white border-2 border-primary-color py-2 px-4 rounded-xl hover:bg-[#fff1e7] transition-colors duration-300">
            Bekijk zakelijke lease
          </button>
        </Link>
      </div>
      <div className="max-w-4xl mx-auto px-4">
        {contentData.map((item, index) => {
          switch (item.type) {
            case "h1":
              return (
                <h1
                  key={index}
                  id="header1"
                  className="text-5xl font-black text-left mt-10 text-primary-color"
                >
                  {item.text}
                </h1>
              );
            case "h2-1":
              return (
                <h2
                  key={index}
                  className="text-3xl font-bold mt-6 text-[#464646]"
                >
                  {item.text}
                </h2>
              );
            case "h2-2":
            case "h2-3":
            case "h2-4":
            case "h2-5":
              return (
                <h2
                  key={index}
                  className="text-3xl font-bold mt-8 text-[#464646]"
                >
                  {item.text}
                </h2>
              );
            case "h1-1":
              return (
                <h2 key={index} id="header2" className="text-md font-bold mt-6">
                  {item.text}
                </h2>
              );
            case "h3-1":
            case "h3-2":
            case "h3-3":
            case "h3-4":
            case "h3-5":
            case "h3-6":
            case "h3-7":
            case "h3-8":
              return (
                <h3
                  key={index}
                  className="text-3xl font-bold mt-8 text-[#464646]"
                >
                  {item.text}
                </h3>
              );
            case "p-1":
              return (
                <p key={index} className="mt-4 text-md leading-relaxed">
                  {item.text}
                </p>
              );
            case "p-2":
            case "p-3":
              return (
                <p key={index} className="mt-4 text-gray-600 leading-relaxed">
                  {item.text}
                </p>
              );
            default:
              return null;
          }
        })}
      </div>
      <div className="mb-[10vh]"></div>
    </div>
  );
};

export default BlogPage;
