import React from "react";
import { BiSad } from "react-icons/bi";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center">
      <BiSad className="text-primary-color mb-2" size={100} />
      <div className="text-center">
        <h1 className="text-5xl font-bold text-primary-color">
          Sorry, er ging iets mis.
        </h1>
        <p className="text-gray-600 w-[30vh] mx-auto mt-4">
          Dese pagina heeft pootjes gekregen. Naar waar ben je op zoek? We
          helpen je graag verder.
        </p>
        <div className="flex justify-evenly mt-5">
          <Link to="/">
            <button className="bg-white text-primary-color border-2 border-primary-color py-2 px-4 rounded-lg font-bold cursor-pointer hover:bg-[#fff1e7] transition-colors duration-300">
              Home
            </button>
          </Link>
          <Link to="/Elektrische-Autos?contract=Private+lease?sortOrder=laag-hoog">
            <button className="bg-white text-primary-color border-2 border-primary-color py-2 px-4 rounded-lg font-bold cursor-pointer hover:bg-[#fff1e7] transition-colors duration-300">
              Lease
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
