import React, { useState } from "react";
import { Link } from "react-router-dom";
import elnllogo from "../assets/PersoonsBusjeHurenLogo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrFormClose } from "react-icons/gr";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="bg-[#f6f8fb] shadow-md py-1 z-20 font-sans w-full">
      <div className="mx-[5vw] flex items-center justify-between">
        <div className="flex items-center ">
          <Link
            to="/"
            className="text-secondary-color text-xl sm:text-2xl font-bold flex items-center"
          >
            <img
              src={elnllogo}
              alt="Logo"
              className="w-auto sm:h-[6vh] h-[5vh] "
            />
            <div>
              <span className="text-primary-color">PersoonsBusje</span>
              <span className="text-secondary-color">Huren.nl</span>
            </div>
          </Link>
        </div>

        <div className="hidden lg:flex items-center space-x-14">
          <Link
            to="/"
            className="text-secondary-color font-bold hover:text-primary-color"
            onClick={closeMenu}
          >
            Home
          </Link>

          <Link
            to="/Aanbod"
            className="text-secondary-color font-bold hover:text-primary-color"
            onClick={closeMenu}
          >
            Aanbod
          </Link>
          <Link
            to="/Over-ons"
            className="text-secondary-color font-bold hover:text-primary-color"
            onClick={closeMenu}
          >
            Over ons
          </Link>
          <Link
            to="/private-lease/"
            className="text-secondary-color font-bold hover:text-primary-color"
            onClick={closeMenu}
          >
            Nieuws
          </Link>
          <Link
            to="/Automerken"
            className="text-secondary-color font-bold hover:text-primary-color"
            onClick={closeMenu}
          >
            Automerken
          </Link>
        </div>

        <div className="lg:hidden">
          <button
            className="text-secondary-color focus:outline-none"
            onClick={toggleMenu}
          >
            {menuOpen ? (
              <GrFormClose size={40} />
            ) : (
              <GiHamburgerMenu size={25} className="mr-2 mt-2" />
            )}
          </button>
        </div>
      </div>

      {menuOpen && (
        <div className="lg:hidden fixed inset-0 bg-gray-100 z-50 transform transition-transform ease-in duration-300">
          <div className="bg-gray-100 z-40 pt-4 pl-4 space-y-4">
            <div className="flex justify-end">
              <button
                className="text-secondary-color focus:outline-none mr-8"
                onClick={toggleMenu}
              >
                <GrFormClose size={40} />
              </button>
            </div>
            <Link
              to="/"
              className="text-secondary-color text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Home
            </Link>
            <Link
              to="/Aanbod"
              className="text-secondary-color text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Aanbod
            </Link>
            <Link
              to="/Over-ons"
              className="text-secondary-color text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Over ons
            </Link>
            <Link
              to="/private-lease/"
              className="text-secondary-color text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Nieuws
            </Link>
            <Link
              to="/Automerken"
              className="text-secondary-color text-4xl font-bold hover:text-primary-color block"
              onClick={closeMenu}
            >
              Automerken
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
